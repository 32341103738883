import styled from 'styled-components'

import mediaqueries from '../styles/media'

const Section = styled.section<{ narrow?: boolean; relative?: boolean; backgroundImage?: string }>`
  width: 100vw;
  max-width: 95vw;
  margin: 0 auto;
  /* padding: 0 4rem; */

  /* @ts-ignore */
  ${mediaqueries.desktop` max-width: 95vw; `}

  ${(p) =>
    p.narrow
      ? mediaqueries.tablet`
          padding: 0 2rem;
          max-width: 80vw;
        `
      : mediaqueries.tablet`
          padding: 0 2vw;
          max-width: 90vw;
          margin: 0 auto;
        `}

  ${mediaqueries.phablet`
    max-width: 100%;
  `};
`

export default Section
