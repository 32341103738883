/**
 * This react helmt code is adapted from
 * https://themeteorchef.com/tutorials/reusable-seo-with-react-helmet.
 *
 * A great tutorial explaining how to setup a robust version of an
 * SEO friendly react-helmet instance.
 *
 *
 * Use the Helmt on pages to generate SEO and meta content!
 *
 * Usage:
 * <SEO
 *   title={title}
 *   description={description}
 *   image={image}
 * />
 *
 */

import * as React from 'react'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import { useTheme } from '@bestyled/contrib-use-color-mode'
import { themeGet } from '@bestyled/system'
import { useI18n } from '@bestyled/primitives'

interface HelmetProps {
  title?: string
  description?: string
  pathname?: string
  image?: string
  url?: string
  canonicalUrl?: string
  published?: string
  timeToRead?: string
}

const seoQuery = graphql`
  {
    allSite {
      edges {
        node {
          siteMetadata {
            social {
              url
              name
            }
            siteUrl
          }
        }
      }
    }
  }
`

const themeUIDarkModeWorkaroundScript = [
  {
    type: 'text/javascript',
    innerHTML: `
    (function() {
      try {
        var mode = localStorage.getItem('theme-color-mode');
        if (!mode) {
          localStorage.setItem('theme-color-mode', 'light');
        } else {
          document.body.classList.add('theme-' + mode);
        }
      } catch (e) {}
    })();
  `
  }
]

export const getFonts = (theme) => {
  const weightsObj = themeGet('fontWeights', { normal: 400 } as any)({ theme })

  const weights = Object.keys(weightsObj)
    .map((k) => weightsObj[k])
    .join(',')

  const result = themeGet(
    'googleFonts',
    [] as any
  )({ theme }).map((i) =>
    React.createElement('link', {
      id: i,
      key: i,
      href: `https://fonts.googleapis.com/css?family=${i}:${weights}`,
      rel: 'stylesheet'
    })
  )

  return result
}

const SEO: React.FC<HelmetProps> = ({
  title,
  description,
  children,
  url,
  image,
  published,
  timeToRead,
  canonicalUrl
}) => {
  const results = useStaticQuery(seoQuery)
  const site = results.allSite.edges[0].node.siteMetadata
  const { t } = useI18n()
  site.title = t(`site.title`)
  site.description = t(`site.description`)

  const twitter = site.social.find((option) => option.name === 'twitter') || {}
  const theme = useTheme()

  const fullURL = (path: string) =>
    path ? `${site.siteUrl}${path}` : site.siteUrl

  url = url || fullURL('/')

  // If no image is provided lets looks for a default novela static image
  image = image || '/preview.jpg'

  // Checks if the source of the image is hosted on Contentful
  if (`${image}`.includes('ctfassets')) {
    image = `https:${image}`
  } else {
    image = fullURL(image)
  }

  const metaTags: any[] = [
    { charset: 'utf-8' },
    {
      'http-equiv': 'X-UA-Compatible',
      content: 'IE=edge'
    },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1'
    },
    {
      name: 'theme-color',
      content: '#fff'
    },
    { itemprop: 'name', content: title || site.title },
    { itemprop: 'description', content: description || site.description },
    { itemprop: 'image', content: image },
    { name: 'description', content: description || site.description },

    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: site.name },
    { name: 'twitter:title', content: title || site.title },
    {
      name: 'twitter:description',
      content: description || site.description
    },
    { name: 'twitter:creator', content: twitter.url },
    {
      name: 'twitter:image',
      content: image
    },

    { property: 'og:title', content: title || site.title },
    { property: 'og:url', content: url },
    { property: 'og:image', content: image },
    {
      property: 'og:description',
      content: description || site.description
    },
    { property: 'og:site_name', content: site.name }
  ]

  if (published) {
    metaTags.push({ name: 'article:published_time', content: published })
  }

  if (timeToRead) {
    metaTags.push({ name: 'twitter:label1', value: 'Reading time' })
    metaTags.push({ name: 'twitter:data1', value: `${timeToRead} min read` })
  }

  const fontLinks = getFonts(theme)

  return (
    <Helmet
      title={title || site.title}
      htmlAttributes={{ lang: 'en' }}
      script={themeUIDarkModeWorkaroundScript}
      meta={metaTags}
    >
      <link rel="canonical" href={canonicalUrl} />
      {fontLinks}
      {children}
    </Helmet>
  )
}

export default SEO
