import * as React from 'react'
import { useRef } from 'react'
import styled from 'styled-components'

import mediaqueries from '../styles/media'

import Icons from './TComponents/icons'

interface HamburgerMenuProps {
  active: boolean
  onClick: (e: any) => void
  asCloseButton: boolean
  isHomepage?: boolean
}

const animateIn = [
  { width: '15px', transform: 'initial' },
  {
    width: '20px',
    transform: 'translate3d(3px, -2px, 0) rotate(90deg)'
  },
  {
    width: '20px',
    transform: 'translate3d(3px, -2px, 0) rotate(90deg)'
  },
  {
    width: '20px',
    transform: 'translate3d(-3px, -2px, 0) rotate(90deg)'
  }
]

const animateOut = [
  {
    width: '20px',
    transform: 'translate3d(-3px, -2px, 0) rotate(90deg)'
  },
  { width: '15px', transform: 'initial' }
]

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ active, onClick, asCloseButton, isHomepage }) => {
  const leftToggleRef = useRef(null)
  const isFirstRun = useRef(true)

  React.useEffect(() => {
    const $toggle = leftToggleRef.current

    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }

    if (active && $toggle) {
      $toggle.animate(animateIn, {
        duration: 900,
        fill: 'both',
        easing: 'cubic-bezier(0.075, 0.82, 0.165, 1)'
      })
    } else if ($toggle) {
      $toggle.animate(animateOut, {
        duration: 250,
        fill: 'both',
        easing: 'cubic-bezier(0.075, 0.82, 0.165, 1)'
      })
    }
  }, [active])

  return (
    <Hamburger active={active} onClick={onClick} aria-label='Navigation Button'>
      {asCloseButton ? (
        <Icons.Ex />
      ) : (
        <>
          <LeftToggle ref={leftToggleRef} active={active} aria-hidden='true' isHomepage={isHomepage} />
          <RightToggle active={active} aria-hidden='true' isHomepage={isHomepage} />
        </>
      )}
    </Hamburger>
  )
}

export default HamburgerMenu

const Hamburger = styled.button<{ active: boolean }>`
  position: relative;
  z-index: 100;
  width: 30px;
  height: 30px;
  opacity: ${(p) => (p.active ? 0.5 : 1)};
  transition: transform 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  display: flex;
  align-self: center;
`

const Toggle = styled.span<{ isHomepage?:boolean }>`
  position: absolute;
  right: 1px;
  height: 1px;
  background: ${(p) => (p.isHomepage ? p.theme.colors.card : p.theme.colors.headings)};
  transition: transform 0.4s cubic-bezier(0.075, 0.82, 0.165, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
` as React.FC<{ active?: boolean }>

const LeftToggle = styled(Toggle)`
  top: 30px;
  width: ${(p) => (p.active ? '20px' : '15px')};
  ${p => p.active ? 'translate3d(3px, 4px, 0) rotate(90deg)' : 'initial'};

  ${mediaqueries.phablet`
  top: 15px;
  `};
` as React.FC<any>

const RightToggle = styled(Toggle)`
  top: 24px;
  width: 20px;
  transform: ${(p) =>
    p.active ? 'translate3d(3px, 4px, 0) rotate(90deg)' : 'initial'};

  ${mediaqueries.phablet`
  top: 9px;
`};
`
