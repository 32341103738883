import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useI18n } from '@bestyled/primitives'
import mediaqueries from '../styles/media'

const LanguageLinks: React.FC<{
  active: boolean
  handleClick: Function
  isHomepage?: boolean
}> = ({ active, handleClick, isHomepage }) => {
  const { locale, languages, getLangPathForLocale } = useI18n()

  return (
    <>
      {Object.keys(languages).map((buttonLocale, i) => (
        <NavLanguage
          key={buttonLocale}
          data-active={active || undefined}
          to={getLangPathForLocale(buttonLocale)}
          delay={0}
          as={Link}
          tabIndex={active ? 0 : -1}
          onClick={(event) => handleClick(event, getLangPathForLocale(buttonLocale))}
          data-a11y='false'
          getProps={() => (buttonLocale === locale ? { 'data-iscurrent': 'true' } : null)}
          data-ishomepage={isHomepage}
        >
          {languages[buttonLocale].siteLanguage.toUpperCase()}
        </NavLanguage>
      ))}
    </>
  )
}

export default LanguageLinks

const LanguageLinkContainer = styled.a`
  position: relative;
  margin-left: 3.2rem;
  text-decoration: none;
  max-width: 16px;
  background: inherit;

  &:hover {
    svg {
      &:hover * {
        fill: ${(p) => p.theme.colors.primary};
      }
      * {
        transition: fill 0.25s var(--ease-in-out-quad);
      }
    }
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -50%;
    top: -20%;
    width: 200%;
    height: 160%;
    border: 2px solid ${(p) => p.theme.colors.accent};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  ${mediaqueries.tablet`
    margin: 0 2.2rem;
  `};
`

const NavLanguage = styled.a<any>`
  /* display: flex; */
  font-family: ${(p) => p.theme.fonts.menu};
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-size: ${(p) => p.theme.fontSizes[0]}px;
  align-self: center;
  color: ${(p) => (p['data-ishomepage'] ? p.theme.colors.card : p.theme.colors.headings)};
  /* transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.9) ${(p) => p.delay}ms,
    transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.9) ${(p) => p.delay}ms; */

  border-radius: 4px;
  padding: 2px 4px;
  margin-right: 5px;
  margin-left: 5px;
  /* bottom: 4px; */
  position: relative;

  pointer-events: ${(p) => (p['data-active'] ? 'initial' : 'none')};
  opacity: ${(p) => (p['data-active'] ? (p.disabled ? 0.6 : 0.9) : 0)};
  /* transform: ${(p) => (p['data-active'] ? 'translateX(0)' : 'translateX(12px)')}; */

  &[data-iscurrent='true'] {
    border: 1px solid ${(p) => (!p['data-ishomepage'] ? p.theme.colors.primary : p.theme.colors.buttonHoverBg)};
  }

  &:hover {
    opacity: ${(p) => (p.disabled ? 0.15 : 1)};
  }

  &:focus {
    outline: none;
  }

  ${mediaqueries.tablet`
      margin: 5px 0;

  `};
`
