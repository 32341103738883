import * as React from 'react'
import styled from 'styled-components'
import { hexToRGBA } from '../utils'



export const BackToTop = () => {
  return (
     <BackToTopWrapper className='back-to-top-wrapper'>
      <BackToTopLink href='#top' className='back-to-top-link' aria-label='Scroll to Top'>
        🔝
      </BackToTopLink>
    </BackToTopWrapper>
  )
}

const BackToTopLink = styled.a`
  display: inline-block;
  text-decoration: none;
  font-size: 2.5rem;
  line-height: 5rem;
  text-align: center;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.branded.syncRoseHue[2]};
  /* emoji don't behave like regular fonts
     so this helped position it correctly */
  padding: 0.25rem;
  position: fixed;
  position: sticky;
  pointer-events: all;
  opacity: 0.5;
  color: ${(p) => p.theme.colors.white};

  top: calc(100vh - 9rem);
  margin-left: -3vw;
  z-index: 100;

  ${`&:hover {
    opacity: 1;
    }
  `}
`
const BackToTopWrapper = styled.div`
  position: absolute;
  top: 101vh;
  right: 0.25rem;
  bottom: 0em;
  width: 3em;
  pointer-events: none;
`