import * as React from 'react'
import styled from 'styled-components'
// Replace if not using @cc
import * as Icons from 'react-icons/si'
import mediaqueries from '../styles/media'
interface SocialLinksProps {
  links: {
    name: string
    url: string
  }[]
  fill?: string
}

const icons = {
  behance: Icons.SiBehance,
  dribbble: Icons.SiDribbble,
  linkedin: Icons.SiLinkedin,
  twitter: Icons.SiTwitter,
  facebook: Icons.SiFacebook,
  instagram: Icons.SiInstagram,
  devto: Icons.SiDevdotto,
  github: Icons.SiGithub,
  stackoverflow: Icons.SiStackoverflow,
  youtube: Icons.SiYoutube,
  medium: Icons.SiMedium,
  unsplash: Icons.SiUnsplash,
  patreon: Icons.SiPatreon,
  paypal: Icons.SiPaypal,
  digitalocean: Icons.SiDigitalocean,
  tripadvisor: Icons.SiTripadvisor
}

const getHostname = (url) => {
  return new URL(url.toLowerCase()).hostname.replace('www.', '').split('.')[0]
}

const SocialLinks: React.FC<SocialLinksProps> = ({ links, fill = '#73737D' }) => {
  if (!links) {
    return null
  }

  return (
    <>
      {links.map((option) => {
        const name = option.name || getHostname(option.url)
        const Icon = icons[name]
        if (!Icon) {
          throw new Error(`unsupported social link name=${name} / url=${option.url}`)
        }
        return (
          <SocialIconContainer
            key={ name }
            target='_blank'
            rel='noopener nofollow'
            data-a11y='false'
            aria-label={ `Link to ${option.url}` }
            href={ option.url }
            data-name={name}
          >
            <Icon fill={fill} />
            <Hidden>Link to ${option.url}</Hidden>
          </SocialIconContainer>
        )
      })}
    </>
  )
}

export default SocialLinks

const SocialIconContainer = styled.a`
  position: relative;
  margin-left: 3.2rem;
  text-decoration: none;
  max-width: 16px;
  background: inherit;

  &:hover {
    svg {
      &:hover * {
        fill: ${(p) => {
          const name = p['data-name'];
          return p.theme.colors.branded[`${name}Color`] || p.theme.colors.branded.syncblueColor
        }};
      }
      * {
        transition: fill 0.25s var(--ease-in-out-quad);
      }
    }
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -50%;
    top: -20%;
    width: 200%;
    height: 160%;
    border: 2px solid ${(p) => p.theme.colors.accent};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  ${mediaqueries.tablet`
    margin: 0 2.2rem;
  `};
`

const Hidden = styled.span`
  width: 0px;
  height: 0px;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  display: inline-block;
`
