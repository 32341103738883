import { css } from 'styled-components'

import { toCssSize } from '@bestyled/system'
import { theme  } from './theme'
interface IMedia {
  phone_small: any
  phone: any
  phablet: any
  phablet_up: any
  tablet: any
  tablet_large: any
  tablet_up: any
  desktop: any
  desktop_up: any
  desktop_medium: any
  desktop_large: any
}

/**
 * All breakpoints can be found inside of theme.breakpointsCombined.
 * Each is turned in to a min + 1 and max-width version.
 *
 * There are also break points to cover coarse and fine pointer devices
 *
 * @example
 *
 *    ${mediaqueries.phone` width: 100px; `};
 *    ${mediaqueries.tablet_up` width: 200px; `};
 */

export const mediaqueries = theme.breakpointsCombined.reduce(
  (acc, [label, size], i) => ({
    ...acc,
    // max-width media query e.g. mediaqueries.desktop
    [label]: (first, ...args) => css`
      @media (max-width: ${toCssSize(size, 'em')}) {
        ${css(first, ...args)};
      }
    `,
    // min-width media query e.g. mediaqueries.desktop_up
    // This is the breakpoint prior's size +1
    [`${label}_up`]: (first, ...args) => css`
      @media (min-width: ${toCssSize(
          theme.breakpointsCombined[i - 1][1] + 1,
          'em'
        )}) {
        ${css(first, ...args)};
      }
    `
  }),
  {}
) as IMedia

export default mediaqueries
